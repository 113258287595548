html,
body,
#root,
.App {
  background: #212225;
  color: #ffffff;
  color-scheme: dark;
  display: grid;
  width: 100%;
  place-items: center;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  padding: 0;
  margin: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  overflow-x: hidden;
  gap: 1rem;
}
marquee {
  position: fixed;
  z-index: 99999999;
  background: red;
  color: white;
  top: 0;
  right: 0;
  left: 0;
}
#root {
  margin-top: 1rem;
}
.app {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  min-height: 100svh;
  overflow-y: auto;
  gap: 1rem;
}
.button_link {
  all: unset;
  color: rgb(255 255 255 /0.5);
  font-weight: lighter;
  font-size: 1.5rem;
}

.button_link:hover {
  text-decoration: underline;
  cursor: pointer;
}
input[type='text'] {
  padding-inline: 1rem;
  padding-block: calc(1.25rem - 0.5pc);
  border: none;
  border-radius: 15px;
}

.full-screen {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100vw !important;
  height: 100vh !important;
  background: #212225 !important;
  z-index: 1000;
  padding: 1rem;
  overflow: auto;
}

::-webkit-scrollbar {
  display: none;
  width: 0;
  /* Plese change the currrent version of this fix. this is just for compatibility, but usage wise, it is not th ebest option */
}

.gallery_card--detail {
  height: 35%;
}

.card_grid {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.card_grid label {
  color: #858585;
  font-size: 1.4rem;
  font-weight: 600;
}

input {
  accent-color: #e66b00;
}

.card-view {
  position: relative;
}

.card-view_close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 5000;
}

.card-view_grid {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: 1fr;
}

.card-view_desc {
  font-size: 1.7rem;
  color: #858585;
  line-height: 1.6;
}

.card-view_desc b {
  color: white;
}

/* #region search */
.app_search {
  position: sticky;
  padding-inline: 1rem;
  padding-block: calc(2.5rem - 1pc);
  border: none;
  border-radius: 15px;
  min-width: 40rem;
}

.app_search:focus-visible {
  outline: none;
}
/* #endregion */
/* #region userinfo */
.user_info {
  text-align: center;
}

.user_info h1 {
  font-size: 5.8275em;
  font-weight: 600;
}

.bold {
  font-weight: bold;
}
/* #endregion */
/* #region gallery */
.app_gallery {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
  padding: 1rem;
  gap: 1rem;
}
.gallery_card {
  padding: 1rem;
  border-radius: 0.5rem;
  min-width: 20rem;
  min-height: 20rem;
  font-size: 24px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-direction: row;
  background: #000000;
  position: relative;
  inset: 0;
  isolation: isolate;
  overflow: hidden;
}
.card_title {
  font-size: 30px;
  font-weight: 600;
  white-space: nowrap;
}

.card_rating {
  font-weight: 500;
}

.card-bg {
  background-image: var(--background);
  background-size: cover;
  background-position: 50% 50%;
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  mask-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.107) 80%
  );
  mask-size: cover;
  mask-repeat: no-repeat;
}
/* #endregion */
/* #region modal */
.modal {
  position: fixed;
  z-index: 200;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 2rem;
  border-radius: 0.75rem;
  background: #1d1d1d;
  max-width: 35%;
  aspect-ratio: 3/4;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow-y: auto;
}
.modal_title {
  font-size: 5rem;
  font-weight: 700;
}
.modal_desc {
  color: #ffffff;
  opacity: 0.6;
}

.modal form {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  font-size: 1.2em;
}
.modal_close {
  position: absolute;
  top: 1rem;
  right: 1rem;
}
/* #endregion */

@media (min-width: 2500px) {
  .app_gallery {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media (min-width: 1500px) {
  .app_gallery {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 1000px) {
  .card-view_grid {
    grid-template-columns: 1fr;
  }
  .app_gallery {
    grid-template-columns: repeat(2, 1fr);
  }
  .header,
  .user_info {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    /* background: red; */
    width: 100vw;
    padding: 0;
    margin: 0;
    min-height: 5rem;
  }
  .user_info h1 {
    display: none;
  }
  .user_info button {
    all: unset;
    padding-inline: 0.75rem;
    padding-block: 0.5rem;
    background: #e66b00;
    border-radius: 0.5rem;
  }
  div.modal {
    max-width: 100% !important;
    width: 100% !important;
    height: 100% !important;
    border-radius: 0;
  }
}
@media (max-width: 675px) {
  .app_search {
    min-width: 0;
    width: 100%;
  }
  .app_gallery {
    grid-template-columns: 1fr;
  }
}
